import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const FlexRowCenteredWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Flex = styled.div`
  display: flex;
`

const Text = styled.div`
  text-transform: uppercase;
  white-space: pre-line;
  font-size: 2em;
  text-align: start;
  margin-bottom: 20px;
`

const LinkRow = ({ links }) => (
  <FlexRowCenteredWrap>
    {links.map((link, i) => (
      <Flex key={i}>
        <a
          href={link.to}
          key={`link-row-${i}${link.to}`}
          target="_blank"
          rel="noopener noreferrer"
          download={!link.to.includes('http')}
        >
          <Text>{link.text}</Text>
        </a>
        {i !== links.length - 1 && <Text>&nbsp;/&nbsp;</Text>}
      </Flex>
    ))}
  </FlexRowCenteredWrap>
)

LinkRow.propTypes = {
  links: PropTypes.array.isRequired,
}

export default LinkRow
