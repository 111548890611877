import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { Grid, Item, Row } from '../components/flexGrid'
import Image from '../components/image'
import LinkRow from '../components/linkRow'
import Embedd from '../components/embedd'

const Root = styled(Grid)`
  h1,
  h2 {
    margin-bottom: 0px;
  }
`

const Members = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const Embedds = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`

const ReleasePage = ({ pageContext, data }) => {
  const {
    image,
    title,
    members,
    label,
    releaseDate,
    links,
    embedds,
    lang,
  } = pageContext
  const { project } = data
  const date = new Date(releaseDate)
  const year = date.getFullYear()
  return (
    <Root>
      <Row>
        <Item w={7}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {project && project?.uri !== 'none' && (
              <h1>
                <Link to={`/${lang}/projects/${project?.uri}`}>
                  {project?.title}
                </Link>
              </h1>
            )}
            <h1>
              {project && project.uri !== 'none' && <>&nbsp;-&nbsp;</>}«{title}»
            </h1>
          </div>
          <h2>
            {label && `${label}, `}
            {year}
          </h2>
          <LinkRow links={links} />
          <Members>
            {members.map((member) => (
              <span key={member} dangerouslySetInnerHTML={{ __html: member }} />
            ))}
          </Members>
          <Embedds>
            {embedds &&
              embedds.map((embedd, i) => (
                <Embedd src={embedd} key={`player${i}`} />
              ))}
          </Embedds>
        </Item>
        <Item w={5}>
          <Image src={image} style={{}} />
        </Item>
      </Row>
    </Root>
  )
}

export const pageQuery = graphql`
  query ReleasePage($artistUri: String) {
    project: projectsJson(uri: { eq: $artistUri }) {
      title
      uri
    }
  }
`

ReleasePage.propTypes = {
  pageContext: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }).isRequired,
}

export default ReleasePage
